import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as Tabs from '@radix-ui/react-tabs';
import { Text } from 'components/Text/Text';
import tabViolet from 'images/backgrounds/tabViolet.svg';
import tabGreen from 'images/backgrounds/tabGreen.svg';
import tabOrange from 'images/backgrounds/tabOrange.svg';
import { ArrowRight } from 'components/icons/ArrowRight';
import { PortfolioTabContent } from './PortfolioTabContent/index';
import { BorrowerTabContent } from './BorrowerTabContent';
import { LendersTabContent } from './LendersTabContent';
import { useModal } from '../../../hooks/useModal';
import { BorrowerInquiryModal } from '../../Modals/BorrowerInquiryModal';

const sectionColors = {
  portfolio: '#9A75FF',
  borrowers: '#FFA350',
  lenders: '#12B999',
};

export const TabSection = () => {
  const ref = useRef(null);
  const [section, setSection] = useState('portfolio');
  const [stickBar, setStickBar] = useState(false);
  const { isShown, toggle } = useModal();
  const [borrowerInquiry, setBorrowerInquiry] = useState(false);

  const handleBorrowerInquiry = e => {
    toggle();
    setBorrowerInquiry(true);
  };

  const color = sectionColors[section];
  useEffect(() => {
    function handleScroll() {
      if (!ref.current) { return; }

      const isInViewport = ref.current.offsetTop < window.scrollY;
      setStickBar(isInViewport);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <StyledTitle size="large">Explore how TrueFi can help you:</StyledTitle>
      <Tabs.Root defaultValue="portfolio" onValueChange={(value) => setSection(value)}>
        <TabsList orientation="vertical">
          <Tab value="portfolio" color={color}>
            <TabBackground src={tabViolet} />
            <TextWrapper>
              <TabTitle>Portfolio Managers</TabTitle>
              <TabText size="medium">Design & operate TrueFi portfolios</TabText>
              <Line color={color} />
            </TextWrapper>
            <LinkWrapper>
              <StyledArrow color={color}/>
              <TextLink>Read more</TextLink>
            </LinkWrapper>
          </Tab>
          <Tab value="borrowers">
            <TabBackground src={tabOrange} />
            <TextWrapper>
              <TabTitle>Borrowers</TabTitle>
              <TabText size="medium">Access competitively-priced capital</TabText>
              <Line color={color} />
            </TextWrapper>
            <LinkWrapper>
              <StyledArrow color={color}/>
              <TextLink>Read more</TextLink>
            </LinkWrapper>
          </Tab>
          <Tab value="lenders">
            <TabBackground src={tabGreen} />
            <TextWrapper>
              <TabTitle>Lenders</TabTitle>
              <TabText size="medium">Earn yield across diverse opportunities</TabText>
              <Line color={color} />
              <LinkWrapper>
                <StyledArrow color={color}/>
                <TextLink>Read more</TextLink>
              </LinkWrapper>
            </TextWrapper>
          </Tab>
        </TabsList>
        <ContentWrapper ref={ref}>
          <Tabs.Content value="portfolio">
            <PortfolioTabContent color={color} handleModal={handleBorrowerInquiry} />
          </Tabs.Content>
          <Tabs.Content value="borrowers">
            <BorrowerTabContent color={color} handleModal={handleBorrowerInquiry}/>
          </Tabs.Content>
          <Tabs.Content value="lenders">
            <LendersTabContent color={color} handleModal={handleBorrowerInquiry}/>
          </Tabs.Content>
          {stickBar &&
            <StickyBar>
              <SideBarLink value="portfolio" color={color}>Portfolio Managers</SideBarLink>
              <SideBarLink value="borrowers" color={color}>Borrowers</SideBarLink>
              <SideBarLink value="lenders" color={color}>Lenders</SideBarLink>
            </StickyBar>
          }
        </ContentWrapper>
      </Tabs.Root>

      {
        borrowerInquiry &&
        <BorrowerInquiryModal isShown={isShown} onRequestClose={toggle} />
      }
    </>
  );
};

const TextWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const Line = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: ${({ color }) => color};
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const StyledArrow = styled(ArrowRight)`
  display: none;
  color: ${({ color }) => color};
  transform: rotate(90deg);
`;

const TextLink = styled(Text)`
  display: block;
  font-weight: 500;
  color: #1A5AFF;

  @media (max-width: 900px) {
    font-size: 14px;
  }
`;

const TabsList = styled(Tabs.List)`
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: repeat(3,auto);
  width: fit-content;
  margin: 0 auto;

  @media (max-width: 900px) {
    grid-column-gap: 20px;
    padding: 0 32px;
  }
  @media (max-width: 767px) {
    padding: 0 16px;
  }
`;

const TabTitle = styled.h3`
  position: relative;
  font-size: 28px;
  font-weight: 600;
  line-height: 143%;
  color: #0B0E14;
  margin-bottom: 13px;

  @media (max-width: 900px) {
    margin-bottom: 8px;
    font-size: 22px;
  }
  @media (max-width: 767px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
`;

const TabText = styled(Text)`
  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 26px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const TabBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: .25s ease-in;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Tab = styled(Tabs.Trigger)`
  overflow: hidden;
  position: relative;
  padding: 30px 20px 32px;
  border: 1px solid #D0D6E6;
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  background-color: #fff;
  cursor: pointer;
  width: 100%;
  max-width: 363px;

  @media (max-width: 900px) {
    padding: 16px 16px 20px;
  }

  @media (max-width: 767px) {
    padding: 0 0 25px 0;
    border: none;
  }

  &:hover {
    ${TabBackground} {
      opacity: 1;
      transition: .25s ease-in;
    }
  }

  &[data-state="active"] {
    border: none;
    background-color: #030303;
    color: #ffffff;

    @media (max-width: 767px) {
      background: none;
      color: #0B0E14;
    }

    ${TabTitle},
    ${Text} {
      color: #fff;
      @media (max-width: 767px) {
        font-weight: 600;
        color: #0B0E14;
      }
    }
    
    ${Line} {
      @media (max-width: 767px) {
        display: block;
      }
    }

    ${TabBackground} {
      opacity: 1;
      transition: .25s ease-in;
    }

    ${StyledArrow} {
      display: block;
    }

    ${TextLink} {
      display: none;
    }
  }
`;

const StickyBar = styled(Tabs.List)`
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  border-top: 1px solid #60697C;
  background-color: rgba(3, 3, 3, 0.6);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 1;

  @media (max-width: 900px) {
    display: none;
  }
`;

const SideBarLink = styled(Tabs.Trigger)`
  position: relative;
  display: block;
  height: auto;
  border: none;
  background: none;
  font-size: 18px;
  line-height: 29px;
  color: #7A859E;
  white-space: nowrap;
  cursor: pointer;

  & + & {
    margin-left: 40px;
  }

  &[data-state="active"] {
    color: ${({ color }) => color};
    font-weight: 600;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  background-color: #030303;

  @media (max-width: 900px) {
    background: none;
  }
`;

const StyledTitle = styled(Text)`
  margin-bottom: 48px;
  font-weight: 600;
  text-align: center;
  color: #0B0E14;

  @media (max-width: 900px) {
    display: none;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 120%;
    display: block;
    margin: 0 auto 32px auto;
    max-width: 250px;
  }
`;
