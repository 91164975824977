import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/modules/navigation/navigation';
import { Button, SectionTitle } from '../Components';
import { ArrowRight } from 'components/icons/ArrowRight';
import { SliderNav } from 'components/Slider/SliderNav';

import portfolioImg1 from 'images/tabsSection/lender/portfolio/portfolio1.png';
import portfolioImg2 from 'images/tabsSection/lender/portfolio/portfolio2.png';
import portfolioImg3 from 'images/tabsSection/lender/portfolio/portfolio3.png';

const portfolios = [
  {
    image: portfolioImg1,
    link: 'https://app.truefi.io/portfolios/mainnet/0x7c9b8374b32c75f06c5e5a16ace1822c28f3e87e',
  },
  {
    image: portfolioImg2,
    link: 'https://app.truefi.io/portfolios/optimism/0xa8c2f1571785007c9b5ff039957173e82a48768d',
  },
  {
    image: portfolioImg3,
    link: 'https://app.truefi.io/portfolios/mainnet/0x3eabf546fff0a41edaaf5b667333a84628571318',
  },
];

const breakpoints = {
  320: {
    slidesPerView: 1,
    spaceBetween: 16,
  },
  767: {
    slidesPerView: 2,
    spaceBetween: 24,
  },
  1020: {
    slidesPerView: 3,
    spaceBetween: 24,
  },
};

export const PortfoliosSection = () => (
  <PortfoliosSectionWrapper>
    <SectionTitle>Explore TrueFI's Featured Portfolios</SectionTitle>
    <SwiperWrapper>
      <Swiper
        slidesPerView="auto"
        modules={[Navigation]}
        breakpoints={breakpoints}
        navigation={{
          nextEl: '.nextSlide',
          prevEl: '.prevSlide',
        }}
      >
        {portfolios.map(({ image, link }, idx) => (
          <SwiperSlide key={idx}>
            <Link to={link} target="_blank">
              <SlideImg src={image} alt="" />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      <StyledSliderNav />
    </SwiperWrapper>
    <StyledButton href="http://app.truefi.io/" target="_blank">
      View All Lending Opportunities
      <ArrowRight />
    </StyledButton>
  </PortfoliosSectionWrapper>
);

const PortfoliosSectionWrapper = styled.section`
  margin-bottom: 163px;

  @media (max-width: 900px) {
    margin-bottom: 92px;
  }
`;

const SwiperWrapper = styled.div`
  position: relative;
  margin-top: 24px;

  @media (max-width: 900px) {
    margin-top: 32px;
  }
  @media (max-width: 767px) {
    padding-bottom: 80px;
  }
`;

const StyledButton = styled(Button)`
  margin: 32px auto 0 auto;
`;

const StyledSliderNav = styled(SliderNav)`
  display: none;
  bottom: 0;

  @media (max-width: 767px) {
    display: flex;
  }

  button {
    border-color: #12B999;
    color: #12B999;
  }
`;

const SlideImg = styled.img`
  width: 100%;
`;
