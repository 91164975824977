import React, { useState } from 'react';
import styled from 'styled-components';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import 'swiper/swiper-bundle.min.css';
import 'swiper/modules/navigation/navigation';

import Arrow from 'images/icons/arrow-right-blue.svg';
import { SlideItem } from './SlideItem';
import { SliderNavBtn } from 'components/Slider/SliderNavButton';

const breakpoints = {
  320: {
    slidesPerView: 1,
    spaceBetween: 16,
  },
  767: {
    slidesPerView: 2,
    spaceBetween: 24,
  },
  1020: {
    slidesPerView: 3,
    spaceBetween: 24,
  },
};

SwiperCore.use([Navigation]);

export const InstitutionalUserSlider = ({ slides }) => {
  const [prevEl, setPrevEl] = useState(null);
  const [nextEl, setNextEl] = useState(null);

  return (
    <StyledSwiper
      slidesPerView="auto"
      breakpoints={breakpoints}
      navigation={{ prevEl, nextEl }}
    >
      {slides.map(({ logo, quote, text }, idx) => (
        <SwiperSlide key={idx}>
          <SlideItem logo={logo} quote={quote} text={text} />
        </SwiperSlide>
      ))}

      <SliderNav data-aos="fade-up" data-aos-delay="500">
        <SliderNavBtn className="prevSlide" ref={(node) => setPrevEl(node)}>
          <img src={Arrow} alt="" />
        </SliderNavBtn>
        <SliderNavBtn className="nextSlide" ref={(node) => setNextEl(node)}>
          <img src={Arrow} alt="" />
        </SliderNavBtn>
      </SliderNav>
    </StyledSwiper>
  );
};

const SliderNav = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 30px;

  @media (max-width: 1020px) {
    display: flex;
  }
`;

const StyledSwiper = styled(Swiper)`
  .swiper-slide {
    height: auto;
  }
`;
