import styled, { css } from 'styled-components';

const TextSmallSize = css`
  font-size: 12px;
  line-height: 18px;
`;

const TextDefaultSize = css`
  font-size: 16px;
  line-height: 24px;
`;

const TextMediumSize = css`
  font-size: 18px;
  line-height: 26px;
`;

const TextLargeSize = css`
  font-size: 22px;
  line-height: 32px;

  @media (max-width: 900px){
    font-size: 18px;
  }
  
  @media (max-width: 767px){
    font-size: 14px;
    line-height: 24px;
  }
`;

export const Text = styled.p`
  color: ${({ light }) => light ? '#ffffff' : '#60697C'};
  font-weight: 400;

  ${({ size }) => {
    switch (size) {
      case 'large':
        return TextLargeSize;
      case 'medium':
        return TextMediumSize;
      case 'small':
        return TextSmallSize;
      case 'default':
      default:
        return TextDefaultSize;
    }
  }}
  
  b {
    color: #030303;
    font-weight: 600;
  }
`;
