import React, { useEffect, useState } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';
import styled from 'styled-components';
import { ArrowBottom } from 'components/icons/ArrowBottom';

export const CollapseSection = ({ children, className, trigger }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setIsOpen(window.innerWidth > 767);
    setIsDisabled(window.innerWidth > 767);

    function handleResize () {
      window.innerWidth > 767 ? setIsOpen(true) : setIsOpen(false);
      window.innerWidth > 767 ? setIsDisabled(true) : setIsDisabled(false);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Wrapper defaultOpen={isOpen} disabled={isDisabled} className={className} onOpenChange={setIsOpen}>
      <Trigger>
        {trigger}
        <ArrowWrapper className={isOpen ? '' : 'rotate'}>
          <ArrowBottom />
        </ArrowWrapper>
      </Trigger>
      <Collapsible.Content>
        {children}
      </Collapsible.Content>
    </Wrapper>
  );
};

const Wrapper = styled(Collapsible.Root)`
  border: none;
  border-radius: 4px;

  @media (max-width: 767px) {
    overflow: hidden;
    padding: 16px;
    border: 1px solid #60697C;
  }
`;

export const Trigger = styled(Collapsible.Trigger)`
  position: relative;
  width: 100%;
  background: none;
  border: none;
  text-align: center;
  padding-right: 20px;

  @media (max-width: 767px) {
    text-align: left;
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 6px;
  display: none;
  justify-content: center;
  align-items: center;
  transform: rotate(180deg);
  color: #fff;
  
  &.rotate {
    transform: rotate(0)
  }

  @media (max-width: 767px) {
    display: flex;
  }
`;
