import React from 'react';
import styled from 'styled-components';
import { Text } from 'components/Text/Text';

export const Banner = ({ className, color, title, text, image }) => (
  <BannerWrapper className={className}>
    <BannerImg src={image} />
    <BannerLabelWrapper>
      <BannerLabel>
        <BannerTextWrapper color={color}>
          <BannerLabelTitle>{title}</BannerLabelTitle>
          <BannerText size="medium">{text}</BannerText>
        </BannerTextWrapper>
      </BannerLabel>
    </BannerLabelWrapper>
  </BannerWrapper>
);

const BannerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media (max-width: 900px) {
    max-width: 326px;
    height: auto;
  }
  
  @media (max-width: 767px) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    max-width: initial;
    padding: 0 12px;
  }
`;

export const BannerImg = styled.img`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  @media (max-width: 900px) {
    height: 100%;
  }

  @media (max-width: 767px) {
    position: relative;
    transform: translateY(0);
    width: 100%;
    height: auto;
  }
`;

const BannerLabelWrapper = styled.div`
  position: absolute;
  left: 70px;
  bottom: 0;
  transform: translateY(65%);
  width: 620px;

  @media (max-width: 1200px) {
    position: relative;
    width: 100%;
  }

  @media (max-width: 900px) {
    position: absolute;
    width: auto;
    left: -20px;
    transform: translateY(20%);
  }
  
  @media (max-width: 767px) {
    left: 0;
    width: 100%;
    transform: translateY(0);
  }
`;

const BannerLabel = styled.div`
  box-shadow: 0 75px 48px rgba(6, 20, 57, 0.05), 0 8px 26px rgba(6, 20, 57, 0.05), 0 4px 16px rgba(6, 20, 57, 0.03);
  border-radius: 4px;
  z-index: 1;
`;

const BannerLabelTitle = styled.h3`
  font-size: 52px;
  font-weight: 600;
  line-height: 60px;
  margin-bottom: 9px;

  @media (max-width: 900px) {
    font-size: 32px;
    line-height: 1;
    margin: 0;
  }

  span {
    font-size: 28px;
    font-weight: 400;
    text-transform: uppercase;

    @media (max-width: 900px) {
      font-size: 16px;
    }
  }
`;

const BannerText = styled(Text)`
  color: #fff;
  font-weight: 600;

  @media (max-width: 900px) {
    font-size: 14px;
  }
`;

const BannerTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 133px;
  height: 165px;
  background-color: ${({ color }) => color};
  color: #fff;
  text-align: center;
  white-space: nowrap;

  @media (max-width: 900px) {
    height: 100px;
    min-width: 320px;
    padding: 10px 32px;
  }
`;
