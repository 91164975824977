import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/layout/Container';
import { LogoBox } from 'components/LogoBox';

import Marquee from 'react-marquee-slider';
import times from 'lodash/times';

import certik from 'images/adrian/logos/certik.svg';
import slowmist from 'images/adrian/logos/slowmist.svg';
import immunebytes from 'images/adrian/logos/immunebytes.svg';
import chainsulting from 'images/adrian/logos/chainsulting.svg';

const auditors = [
  {
    logo: certik,
    name: 'certik logo',
  },
  {
    logo: slowmist,
    name: 'slowmist logo',
  },
  {
    logo: immunebytes,
    name: 'immunebytes logo',
  },
  {
    logo: chainsulting,
    name: 'chainsulting logo',
  },
];

export const AuditedBySection = () => (
  <SectionWrapper>
    <div data-aos="flip-up">
      <Marquee key={1} velocity={17}>
        {times(3, Number).map(id => (
          <TitleWrapper key={id}>
            <Title>
              Billions of dollars originated
            </Title>
            <Title key={id}>
              <span>Banking-grade security</span>
            </Title>
            <Title>
              Industry-leading underwriting record
            </Title>
            <Title>
              <span>Institutional lending {'&'} management support</span>
            </Title>
          </TitleWrapper>
        ))}
      </Marquee>
    </div>
    <Container>
      <InnerWrapper>
        <StyledLogoBox title="Audited by..." logo={auditors} delay={250} />
      </InnerWrapper>
    </Container>
  </SectionWrapper>
);

const SectionWrapper = styled.section`
  overflow: hidden;
  margin: 104px 0;

  @media (max-width: 900px) {
    margin: 58px 0 68px;
  }

  @media (max-width: 767px) {
    margin: 80px 0;
  }
`;

const InnerWrapper = styled.div`
  border-bottom: 1px solid #E7EAF3;
  padding-bottom: 104px;

  @media (max-width: 900px) {
    border: none;
    padding: 0;
  }
`;

const StyledLogoBox = styled(LogoBox)`
  margin-top: 80px;
  padding: 0 60px;

  @media (max-width: 900px) {
    margin-top: 32px;
    padding: 0;
  }
  
  @media (max-width: 767px) {
    margin-top: 24px;
    padding: 24px;
  }
`;

const Title = styled.h2`
  font-size: 52px;
  font-weight: 600;
  line-height: 66px;
  color: #1A5AFF;
  margin-right: 80px;

  @media (max-width: 900px) {
    font-size: 42px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 28px;
  }
  
  span {
    color: #0B0E14;
    padding: 0 10px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
`;
