import React from 'react';
import styled from 'styled-components';
import { Text } from 'components/Text/Text';
import { Button, ButtonsRow, Link, LinkText, Title } from 'components/CtaSectionWrapper/CtaElements';
import { ArrowRight } from 'components/icons/ArrowRight';
import { CtaSectionWrapper } from 'components/CtaSectionWrapper';
import { BorrowerInquiryModal } from 'components/Modals/BorrowerInquiryModal';
import { useModal } from 'hooks/useModal';

export const BorrowCtaSection = () => {
  const { isShown: displayBorrowModal, toggle: toggleBorrowModal } = useModal();

  return (
    <CtaSectionWrapper>
      <>
        <TextWrapper>
          <StyledTitle data-aos="fade-up" data-aos-delay="250">Institutional loans in as little as 7 days</StyledTitle>
          <Text size="small" light data-aos="fade-up" data-aos-delay="350">
            * Disclaimer: The information contained in this website, including the historical average APR, are intended for informational purposes only. Some of the information may be dated and may not reflect the most current information or lending rates. Lending rates are based on the creditworthiness of individual borrowers and are subject to change.
          </Text>
        </TextWrapper>
        <ButtonsRow>
          <StyledButton
            onClick={toggleBorrowModal}
            data-aos="fade-up"
            data-aos-delay="500"
          >
            Apply to Borrow
            <ArrowRight />
          </StyledButton>
          <LinkText data-aos="fade-up" data-aos-delay="750">or <Link href="https://discord.com/invite/3tMyMqyqDj" target="_blank" rel="noopener noreferrer">Join the Community</Link></LinkText>
        </ButtonsRow>
      </>

      {
        displayBorrowModal &&
        <BorrowerInquiryModal isShown={displayBorrowModal} onRequestClose={toggleBorrowModal} />
      }
    </CtaSectionWrapper>
  );
};

const TextWrapper = styled.div`
  max-width: 565px;

  @media (max-width: 767px) {
    margin-bottom: 24px;
  }
`;

const StyledTitle = styled(Title)`
  margin-bottom: 24px;

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
`;

const StyledButton = styled(Button)`
  svg {
    margin-left: 12px;
  }
`;
