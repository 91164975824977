import React from 'react';
import styled from 'styled-components';
import quoteIcon from 'icons/quote-small.svg';
import { Text } from 'components/Text/Text';

export const SlideItem = ({ logo, quote, text }) => (
  <Slide>
    <LogoWrapper>
      {logo && <img src={logo} alt="" />}
    </LogoWrapper>
    <QuoteWrapper>
      <QuoteIcon src={quoteIcon} alt=""/>
      <QuoteText size="medium">{quote}</QuoteText>
    </QuoteWrapper>
    <Text>
      {text}
    </Text>
  </Slide>
);

const Slide = styled.div`
  height: 100%;
  padding: 32px;
  border: 1px solid #EFF0F6;
`;

const LogoWrapper = styled.div`
  height: 33px;
  
  img {
    height: 100%;
    width: auto;
  }
`;

const QuoteWrapper = styled.div`
  position: relative;
  padding-left: 32px;
  margin: 32px 0;
`;

const QuoteIcon = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`;

const QuoteText = styled(Text)`
  color: #0B0E14;
  line-height: 24px;
  font-style: italic;
`;
