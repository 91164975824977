import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { CloseIcon } from '../../icons/CloseIcon';
import { useKeyDown } from '../../hooks/useKeyDown';
import {
  Backdrop,
  CloseButton,
  Content,
  Header,
  HeaderText,
  StyledModal,
  Wrapper
} from './style';

export const Modal = ({
  isShown,
  onRequestClose,
  onModalClose,
  children,
  header,
  customStyles,
}) => {
  const [closeRequestSent, setCloseRequest] = React.useState(false);

  useEffect(() => {
    if (closeRequestSent && isShown === false) {
      onModalClose();
    }
  }, [isShown, closeRequestSent, onModalClose]);

  const requestClose = () => {
    setCloseRequest(true);
    onRequestClose();
  };

  useKeyDown('Escape', () => isShown && requestClose());

  const modal = (
    <React.Fragment>
      <Backdrop style={customStyles?.backdrop} onClick={requestClose} />
      <Wrapper>
        <StyledModal style={customStyles?.modal}>
          <Header style={customStyles?.header}>
            <HeaderText>{header}</HeaderText>
            <CloseButton onClick={requestClose}>
              <CloseIcon />
            </CloseButton>
          </Header>
          <Content style={customStyles?.content}>{children}</Content>
        </StyledModal>
      </Wrapper>
    </React.Fragment>
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};
