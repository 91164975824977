import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/layout/Container';
import { ArrowRight } from 'components/icons/ArrowRight';
import portfolioImg from 'images/tabsSection/portfolio/portfolio.svg';
import { InnerManagersControl } from './InnerManagersControl';
import background from 'images/backgrounds/tabsSectionViolet.svg';
import backgroundMobile from 'images/backgrounds/tabSectionVioletMobile.svg';
import { BenefitsSection } from '../BenefitsSection';
import { Banner } from '../Components/Banner';
import {
  SectionWrapper,
  SectionContent,
  Row,
  SectionBackground,
  SectionName,
  StyledText,
  TextWrapper,
  StyledSectionTitleLight,
  Button,
  SectionContainer,
  SectionTitle,
  SectionBackgroundMobile
} from '../Components';
import moneyIcon from 'images/tabsSection/portfolio/money.svg';
import liquidityIcon from 'images/tabsSection/portfolio/liquidity.svg';
import settingsIcon from 'images/tabsSection/portfolio/settings.svg';
import diamondIcon from 'images/tabsSection/portfolio/diamond.svg';
import { CollapseSection } from '../CollapseSection';

const benefits = [
  {
    icon: moneyIcon,
    title: 'Reduced business overhead',
    text: 'TrueFi opens access to lenders, borrowers, and services to let you focus on allocating capital.',
  },
  {
    icon: liquidityIcon,
    title: 'Access On-Chain Liquidity',
    text: 'Tap DeFi\'s billions in liquidity looking for unique financial opportunities.',
  },
  {
    icon: settingsIcon,
    title: 'Deep control of your portfolio',
    text: 'Set the portfolio\'s strategy, fee structure, terms and more.',
  },
  {
    icon: diamondIcon,
    title: 'Best of\n' + 'Blockchain',
    text: 'Enjoy 24/7 operations, global reach, industry-leading security, and unparalleled transparency.',
  },
];

export const PortfolioTabContent = ({ color, handleModal }) => (
  <SectionContainer>
    <SectionWrapper>
      <SectionBackground src={background} />
      <SectionBackgroundMobile src={backgroundMobile} />
      <SectionContent>
        <Container>
          <Row>
            <TextWrapper>
              <SectionName size="medium">PORTFOLIO MANAGERS</SectionName>
              <div>
                <StyledSectionTitleLight color={color} >Why launch your <span>portfolio</span> on TrueFi?</StyledSectionTitleLight>
              </div>
              <StyledText size="medium">
              Bring your financing on-chain to <span>lower costs</span> and tap <span>global liquidity</span>, all while maintaining nuanced <span>controls over your portfolio.</span> For guidance and portfolio services, providers like TrustToken are ready to help your business become DeFi-native.
              </StyledText>
              <Button onClick={handleModal}>
              Become a Portfolio Manager
                <ArrowRight />
              </Button>
            </TextWrapper>
            <Banner color={color} image={portfolioImg} title={<>$ 3,320,000 <span>USD</span></>} text="Earned by Portfolio Managers since launch" />
          </Row>
          <ManagersControl trigger={<StyledSectionTitle>Elements under a portfolio manager&apos;s control</StyledSectionTitle>}>
            <InnerManagersControl />
          </ManagersControl>
          <CollapseSection trigger={<SectionTitle>Benefits of On-Chain Portfolios</SectionTitle>}>
            <BenefitsSection benefits={benefits} />
          </CollapseSection>
        </Container>
      </SectionContent>
    </SectionWrapper>
  </SectionContainer>
);

const ManagersControl = styled(CollapseSection)`
  margin-bottom: 148px;

  @media (max-width: 900px) {
    margin-bottom: 94px;
  }
  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
`;

const StyledSectionTitle = styled(SectionTitle)`
  text-align: left;
`;
