import React, { useState } from 'react';
import styled from 'styled-components';
import { Text } from 'components/Text/Text';
import {
  BaseButtonLinkStyles,
  buttonPrimaryStyles,
  buttonTertiaryStyles
} from 'components/Button/Button';
import { useModal } from 'hooks/useModal';
import { ArrowRight } from 'components/icons/ArrowRight';
import { BorrowerInquiryModal } from 'components/Modals/BorrowerInquiryModal';
import pdf from 'assets/TrueFiSalesDeck.pdf';

export const HeroContent = () => {
  const { isShown, toggle } = useModal();
  const [borrowerInquiry, setBorrowerInquiry] = useState(false);

  const handleBorrowerInquiry = e => {
    toggle();
    setBorrowerInquiry(true);
  };

  return (
    <>
      <HeroContentWrapper>
        <HeroTitle data-aos="fade-up">TrueFi for <span>Institutions</span></HeroTitle>
        <HeroText size="large" data-aos="fade-up" data-aos-delay="250">
          TrueFi capital markets connect <b>lenders</b>, <b>borrowers</b>, and <b>portfolio managers</b> with the world’s best financial opportunities.
        </HeroText>
        <HeroButtonWrapper>
          <GetStartedButton
            onClick={handleBorrowerInquiry}
            data-aos="fade-up"
            data-aos-delay="500"
          >
            Get Started
            <ArrowRight />
          </GetStartedButton>

          <ViewTrueFiButton
            href={pdf}
            download={true}
            target="_blank"
            data-aos="fade-up"
            data-aos-delay="750"
          >
            View TrueFi Deck
            <ArrowRight />
          </ViewTrueFiButton>
        </HeroButtonWrapper>
      </HeroContentWrapper>

      {
        borrowerInquiry &&
        <BorrowerInquiryModal isShown={isShown} onRequestClose={toggle} />
      }
    </>
  );
};

const HeroContentWrapper = styled.div`
  position: relative;
  width: 54.5vw;
  max-width: 660px;
  z-index: 1;

  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }
`;

const HeroTitle = styled.h1`
  font-weight: 600;
  font-size: 64px;
  line-height: 80px;
  color: #030303;
  white-space: nowrap;

  @media (max-width: 900px) {
    font-size: 56px;
    line-height: 80px;
  }

  @media (max-width: 767px) {
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    white-space: initial;
  }

  span {
    color: #1a5aff;

    @media (max-width: 767px) {
      display: block;
    }
  }

  & div {
    display: inline-block;
  }
`;

const GetStartedButton = styled(BaseButtonLinkStyles)`
  ${buttonPrimaryStyles};
  width: 280px;

  @media (max-width: 990px) {
    padding: 0 20px;
    width: fit-content;
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  svg {
    margin-left: 16px;
  }
`;

const ViewTrueFiButton = styled(BaseButtonLinkStyles)`
  ${buttonTertiaryStyles};
  width: 280px;

  @media (max-width: 900px) {
    padding: 0 10px;
    width: fit-content;
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  svg {
    margin-left: 16px;
  }
`;

const HeroText = styled(Text)`
  margin: 40px 0;

  @media (max-width: 900px) {
    margin: 32px 0;
  }

  @media (max-width: 767px) {
    margin: 16px 0 24px 0;
  }
`;

const HeroButtonWrapper = styled.div`
  display: flex;
  gap: 24px;

  @media (max-width: 900px) {
    gap: 20px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 16px;
  }
`;
