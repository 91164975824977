import React from 'react';
import styled from 'styled-components';
import { Hero } from './style';
import { Container } from 'components/layout/Container';
import { LogoBox } from 'components/LogoBox';
import { HeroContent } from './HeroContent';
import { trustedByCompany } from 'constants/trustedByCompany';
import banner from 'src/images/borrowBanner.svg';
import bannerMobile from 'src/images/borrowBannerMobile.png';
import background from 'images/borrowBannerBackground.svg';

export const HeroSection = () => (
  <Hero>
    <Container>
      <Row>
        <HeroContent />
        <BannerWrapper data-aos="fade-left" data-aos-delay="250">
          <BackgroundColor src={background} />
          <Banner src={banner} alt="" />
          <BannerMobile src={bannerMobile} alt="" />
        </BannerWrapper>
      </Row>
      <StyledHeroLogoBox logo={trustedByCompany} title="Trusted by..." delay={1000} />
    </Container>
  </Hero>
);

const Row = styled.div`
  display: grid;
  grid-template-columns: 660px 1fr;
  grid-column-gap: 160px;
  margin-bottom: 146px;

  @media (max-width: 1440px){
    grid-column-gap: 64px;
  }

  @media (max-width: 1024px){
    grid-column-gap: 0;
  }

  @media (max-width: 900px){
    grid-template-columns: 415px 1fr;
    grid-column-gap: 90px;
    margin-bottom: 86px;
  }
  
  @media (max-width: 767px){
    grid-template-columns: 1fr;
    margin-bottom: 0;
  }
`;

const BannerWrapper = styled.div`
  position: relative;
  height: 100%;

  @media (max-width: 767px){
    display: flex;
    flex-direction: column;
  }
`;

const Banner = styled.img`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-40%);

  @media (max-width: 900px){
    height: 100%;
    transform: translateY(-25%);
  }

  @media (max-width: 767px){
    display: none;
  }
`;

const BannerMobile = styled.img`
  display: none;
  width: 100%;
  height: auto;

  @media (max-width: 767px){
    display: block;
  }
`;

const StyledHeroLogoBox = styled(LogoBox)`
  padding: 48px 60px 56px;
  transform: translateY(42px);
  box-shadow: 0 24px 60px rgba(2, 10, 33, 0.04);

  @media (max-width: 1024px) {
    padding: 32px;
    transform: translateY(0);
  }

  @media (max-width: 767px) {
    padding: 24px;
  }
`;

const BackgroundColor = styled.img`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-20%, -40%);

  @media (max-width: 900px){
    max-height: 600px;
    transform: translate(-35%, -30%);
  }

  @media (max-width: 767px) {
    display: none;
  }
`;
