import React from 'react';
import styled from 'styled-components';
import background from 'images/backgrounds/tabSectionGreen.svg';
import backgroundMobile from 'images/backgrounds/tabSectionGreenMobile.svg';
import bannerImage from 'images/tabsSection/lender/lender.svg';
import moneyIcon from 'images/tabsSection/lender/money.svg';
import liquidity from 'images/tabsSection/lender/liquidity.svg';
import lock from 'images/tabsSection/lender/lock.svg';
import glass from 'images/tabsSection/lender/glass.svg';
import {
  Button,
  Row,
  SectionBackground, SectionBackgroundMobile, SectionContainer,
  SectionContent,
  SectionName, SectionTitle, SectionWrapper,
  StyledSectionTitleLight,
  StyledText,
  TextWrapper
} from '../Components';
import { Container } from 'components/layout/Container';
import { ArrowRight } from '../../../icons/ArrowRight';
import { Banner, BannerImg } from '../Components/Banner';
import { BenefitsSection } from '../BenefitsSection';
import { PortfoliosSection } from './PortfoliosSection';
import { CollapseSection } from '../CollapseSection';

const benefits = [
  {
    icon: moneyIcon,
    title: 'Competitive Returns',
    text: 'Take advantage of some of the highest and most consistent returns in DeFi on a growing range of investment products.',
  },
  {
    icon: lock,
    title: 'Default Protection',
    text: 'Your loaned assets are assured by TrueFi’s SAFU fund and TRU stakers, offering millions of dollars in coverage against loan default.',
  },
  {
    icon: glass,
    title: 'Transparency',
    text: 'Track every dollar loaned to TrueFi’s vetted borrowers on-chain, as well as regular attestations of TRU token treasury.',
  },
  {
    icon: liquidity,
    title: 'Liquidity',
    text: 'Lend with no lockup period and deep exit liquidity, giving you ultimate control of your assets.',
  },
];

export const LendersTabContent = ({ color, handleModal }) => (
  <SectionContainer>
    <SectionWrapper>
      <SectionBackground src={background} />
      <SectionBackgroundMobile src={backgroundMobile} />
      <SectionContent>
        <Container>
          <Row>
            <TextWrapper>
              <SectionName size="medium">LENDERS</SectionName>
              <div>
                <StyledSectionTitleLight color={color}><span>Earn high yield</span> on <br/> idle capital</StyledSectionTitleLight>
              </div>
              <StyledText size="medium">
              The TrueFi capital market offers <span>+10% APY</span> opportunities on dollar-backed loans, across a diverse range of crypto-native and real world portfolios and strategies.
              </StyledText>
              <Button onClick={handleModal}>
              Become a Lender
                <ArrowRight />
              </Button>
            </TextWrapper>
            <StyledBanner color={color} image={bannerImage} title={<>$25 M <span>USD</span></>} text="Total Interest Earned" />
          </Row>
          <PortfoliosSection />
          <CollapseSection trigger={<SectionTitle>Enjoy attractive, sustainable yields across a diverse list of opportunities</SectionTitle>}>
            <BenefitsSection benefits={benefits} />
          </CollapseSection>
        </Container>
      </SectionContent>
    </SectionWrapper>
  </SectionContainer>
);

const StyledBanner = styled(Banner)`
  ${BannerImg} {
    transform: translateY(-55%);

    @media (max-width: 767px) {
      transform: translateY(10%);
    }
  }
`;
