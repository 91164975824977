import styled from 'styled-components';

export const SliderNavBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border: 1px solid #1A5AFF;
  border-radius: 50%;
  background: transparent;
  transform: rotate(180deg);
  cursor: pointer;
  
  & + & {
    transform: rotate(0deg);
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
