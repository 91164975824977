import React from 'react';
import styled from 'styled-components';
import { Text } from 'components/Text/Text';

export const InnerStepSection = () => (
  <GridSection>
    <Card>
      <Step size="medium">Step 1</Step>
      <CardTitle>Onboarding</CardTitle>
      <CardList>
        <li>Complete KYC/AML</li>
        <li>Complete financial review</li>
        <li>Sign a Master Loan <br/> Agreement</li>
      </CardList>
    </Card>
    <Card>
      <Step size="medium">Step 2</Step>
      <CardTitle>Approval</CardTitle>
      <CardList>
        <li>Community approves your borrower application</li>
        <li>Receive access to Borrower Dashboard on TrueFi app</li>
      </CardList>
    </Card>
    <Card>
      <Step size="medium">Step 3</Step>
      <CardTitle>Funding</CardTitle>
      <CardList>
        <li>Request a loan on-chain</li>
        <li>Receive funding</li>
        <li>Repay your loan in the dashboard at the end of the term</li>
      </CardList>
    </Card>
  </GridSection>
);

const GridSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 51px;
  margin-top: 48px;

  @media (max-width: 900px) {
    grid-column-gap: 20px;
    margin-top: 32px;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
    margin-top: 16px;
  }
`;

const Step = styled(Text)`
  color: #FFA350;
  font-weight: 600;

  @media (max-width: 900px) {
    font-size: 16px;
  }
`;

const CardTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #FFFFFF;

  @media (max-width: 900px) {
    font-size: 22px;
  }
`;

const CardList = styled.ul`
  list-style: initial;
  padding-left: 20px;

  li {
    font-size: 18px;
    line-height: 32px;
    color: #D0D6E6;

    @media (max-width: 900px) {
      font-size: 14px;
    }
  }
`;

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 35px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;

  @media (max-width: 900px) {
    padding: 16px;
    background-color: #1C1C1C;
  }

  &:last-child {
    background-color: #fff;

    ${CardTitle} {
      color: #030303;
    }

    li {
      color: #60697C;
    }

    &:before {
      display: none;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: calc(100% + 16px);
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-width: 20px 0 20px 20px;
    border-color: transparent transparent transparent #FFA350;
    border-style: solid;

    @media (max-width: 900px) {
      left: calc(100% + 5px);
      border-width: 10px 0 10px 10px;
    }

    @media (max-width: 767px) {
      top: calc(100% + 5px);
      left: 50%;
      transform: translateX(-50%) rotate(90deg);
    }
  }
`;
