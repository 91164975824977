import React from 'react';
import styled from 'styled-components';
import { Text } from 'components/Text/Text';
import fee from 'images/tabsSection/portfolio/fee.svg';
import duration from 'images/tabsSection/portfolio/duration.svg';
import restriction from 'images/tabsSection/portfolio/restrictions.svg';
import strategy from 'images/tabsSection/portfolio/strategy.svg';

export const InnerManagersControl = () => (
  <GridSection>
    <Card>
      <div>
        <CardImg src={restriction} alt=""/>
        <Title>Lender Selection</Title>
        <CardText size="medium">Choose your lender restrictions, from open global access, up to bank-grade KYC and geo-specific availability.</CardText>
      </div>
    </Card>
    <Card>
      <div>
        <CardImg src={fee} alt=""/>
        <Title>Fee Structure</Title>
        <CardText size="medium">Choose your management and performance fees.</CardText>
      </div>
    </Card>
    <Card>
      <div>
        <CardImg src={strategy} alt=""/>
        <Title>Strategy</Title>
        <CardText size="medium">Oversee withdrawals, deposits, and allocations in your portfolio.</CardText>
      </div>
    </Card>
    <Card>
      <div>
        <CardImg src={duration} alt=""/>
        <Title>Duration</Title>
        <CardText size="medium">Decide the length of your portfolio as well as the length of individual loans.</CardText>
      </div>
    </Card>
  </GridSection>
);

const GridSection = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 24px;
  margin-top: 48px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 24px;
    margin-top: 32px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    margin-top: 16px;
  }
`;

const Card = styled.div`
  display: grid;
  grid-row-gap: 32px;
`;

const Title = styled(Text)`
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  margin-bottom: 8px;
`;

const CardText = styled(Text)`
  color: #D0D6E6;
`;

const CardImg = styled.img`
  margin-bottom: 32px;
`;
