import React from 'react';
import styled from 'styled-components';
import { Text } from 'components/Text/Text';

export const BenefitsSection = ({ benefits }) => (
  <section>
    <GridSection>
      {benefits.map(({ icon, title, text }, idx) => (
        <Card key={idx}>
          <CardIcon src={icon} alt=""/>
          <div>
            <CardTitle>{title}</CardTitle>
            <StyledText>{text}</StyledText>
          </div>
        </Card>
      ))}
    </GridSection>
  </section>
);

const GridSection = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 24px;
  margin-top: 72px;

  @media (max-width: 900px) {
    margin-top: 64px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
    margin-top: 24px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  text-align: center;

  @media (max-width: 767px) {
    flex-direction: initial;
    align-items: flex-start;
    text-align: left;
    gap: 16px;
  }
`;

const CardIcon = styled.img`
  margin: 0 auto;

  @media (max-width: 767px) {
    margin: 0;
  }
`;

const CardTitle = styled.h3`
  font-size: 24px;
  line-height: 32px;
  color: #FFFFFF;
  margin-bottom: 16px;

  @media (max-width: 900px) {
    font-size: 16px;
  }
  @media (max-width: 767px) {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 24px;
  }
`;

const StyledText = styled(Text)`
  color: #D0D6E6;

  @media (max-width: 900px) {
    font-size: 12px;
  }
  @media (max-width: 767px) {
    font-size: 14px;
  }
`;
