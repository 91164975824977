import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/layout/Container';
import { SectionTitleDark } from 'components/Text/SectionTitle';
import quote from 'icons/quote.png';
import { InstitutionalUserSlider } from './Slider/InstitutionalUserSlider';
import { institutionalUsers } from 'constants/institutionalUsers';

export const InstitutionalUsersSection = () => (
  <section>
    <Container>
      <TitleWrapper data-aos="fade-up">
        <Quote src={quote} />
        <StyledSectionTitleDark>Our institutional users on <span>TrueFi</span></StyledSectionTitleDark>
      </TitleWrapper>
      <div data-aos="fade-up" data-aos-delay="250">
        <InstitutionalUserSlider slides={institutionalUsers} />
      </div>
    </Container>
  </section>
);

const StyledSectionTitleDark = styled(SectionTitleDark)`
  span {
    color: #1A5AFF;
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  width: fit-content;
  margin: 0 auto 98px auto;
  padding-left: 122px;

  @media (max-width: 900px) {
    margin-bottom: 90px;
    padding-left: 58px;
  }
  
  @media (max-width: 767px) {
    margin-bottom: 32px;
    padding-left: 66px;
  }
`;

const Quote = styled.img`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  @media (max-width: 900px) {
    width: 161px;
    height: auto;
  }

  @media (max-width: 767px) {
    width: 96px;
  }
`;
