import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  min-width: 375px;
  outline: 0;

  @media screen and (max-width: 576px) {
    max-width: 360px;
    width: 95%;
    min-width: 0;
    left: 50%;
  }
`;
export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #0b0e14;
  z-index: 500;
`;
export const StyledModal = styled.div`
  z-index: 100;
  background: white;
  position: relative;
  margin: auto;
  border-radius: 8px;
`;
export const Header = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  padding: 26px 24px 26px 24px;
  font-size: 22px;
  line-height: 32px;
  font-weight: 400;

  @media screen and (max-width: 576px) {
    padding: 19px 16px 20px 16px;
  }
`;
export const HeaderText = styled.div`
  align-self: center;
  color: #141625;
  max-width: 18rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;
export const CloseButton = styled.button`
  border: none;
  border-radius: 3px;
  padding: 2px 4px;
  background: none;
  :hover {
    cursor: pointer;
  }
  :active,
  :focus {
    outline: none;
    border: none;
  }
`;
export const Content = styled.div`
  padding: 24px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 80vh;
  box-sizing: border-box;

  @media screen and (max-width: 576px) {
    padding: 20px 16px;
  }
`;
