import React from 'react';
import { Container } from 'components/layout/Container';
import {
  Button,
  Row,
  SectionBackground, SectionBackgroundMobile, SectionContainer, SectionContent,
  SectionName, SectionTitle,
  SectionWrapper,
  StyledSectionTitleLight,
  StyledText,
  TextWrapper
} from '../Components';
import { Banner } from '../Components/Banner';
import bannerImage from 'images/tabsSection/borrower/banner.svg';
import background from 'images/backgrounds/tabSectionOrange.svg';
import backgroundMobile from 'images/backgrounds/tabSectionOrangeMobile.svg';
import { ArrowRight } from '../../../icons/ArrowRight';
import { BenefitsSection } from '../BenefitsSection';
import moneyIcon from 'images/tabsSection/borrower/money.svg';
import arrow from 'images/tabsSection/borrower/arrow.svg';
import lock from 'images/tabsSection/borrower/lock.svg';
import document from 'images/tabsSection/borrower/document.svg';
import { InnerStepSection } from './InnerStepSection';
import { CollapseSection } from '../CollapseSection';
import styled from 'styled-components';

const benefits = [
  {
    icon: moneyIcon,
    title: 'Capital\n' + 'Efficiency',
    text: 'For the first time in DeFi, borrow at the most competitive rates with no capital lockup requirements.',
  },
  {
    icon: arrow,
    title: 'Competitive\n' + 'Lending Rates',
    text: 'Enjoy the best market-driven interest rates on unsecured debt, locked in for the duration of your loan.',
  },
  {
    icon: lock,
    title: 'Privacy\n' + 'Preserving',
    text: 'Options for anonymized borrower applications, with upcoming zero-knowledge proof support.',
  },
  {
    icon: document,
    title: 'Credit\n' + 'History',
    text: 'Develop your borrower profile & repayment history to benefit from the most favorable loan terms on TrueFi and beyond.',
  },
];

export const BorrowerTabContent = ({ color, handleModal }) => (
  <SectionContainer>
    <SectionWrapper>
      <SectionBackground src={background} />
      <SectionBackgroundMobile src={backgroundMobile} />
      <SectionContent>
        <Container>
          <Row>
            <TextWrapper>
              <SectionName size="medium">BORROWERS</SectionName>
              <div>
                <StyledSectionTitleLight color={color}><span>3 steps</span> to your first TrueFi loan</StyledSectionTitleLight>
              </div>
              <StyledText size="medium">
                Enjoy the best capital efficiency and most competitive rates in DeFi from TrueFi, first protocol to offer <span>on-chain credit lines.</span>
              </StyledText>
              <Button onClick={handleModal}>
                Become a Borrower
                <ArrowRight />
              </Button>
            </TextWrapper>
            <Banner color={color} image={bannerImage} title={<>~$1.5 B <span>USD</span></>} text="Total Loans Originated, Without Default" />
          </Row>
          <StepSection trigger={<StyledSectionTitle>Borrow in as little as 7 days</StyledSectionTitle>}>
            <InnerStepSection />
          </StepSection>
          <CollapseSection trigger={<SectionTitle>Competitive rates with no collateral</SectionTitle>}>
            <BenefitsSection benefits={benefits}/>
          </CollapseSection>
        </Container>
      </SectionContent>
    </SectionWrapper>
  </SectionContainer>
);

const StepSection = styled(CollapseSection)`
  margin-bottom: 148px;

  @media (max-width: 900px) {
    margin-bottom: 92px;
  }
  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
`;

const StyledSectionTitle = styled(SectionTitle)`
  text-align: left;
`;
