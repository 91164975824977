import React, { useEffect } from 'react';
import styled from 'styled-components';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { HeroSection } from 'components/BorrowerMarketing/HeroSection';
import SEO from 'components/seo';
import { Footer } from 'components/Footer/Footer';
import { Header } from 'components/Header';
import { LearnSection } from 'components/LearnSection';
import {
  InstitutionalUsersSection
} from 'components/BorrowerMarketing/InstitutionalUsersSection/InstitutionalUsersSection';
import { AuditedBySection } from 'components/BorrowerMarketing/AuditedBySection';
import { BorrowCtaSection } from 'components/BorrowerMarketing/BorrowCtaInner';
import { ExploreTruefiSection } from 'components/BorrowerMarketing/ExploreTruefiSection/ExploreTruefiSection';

const BorrowerContainer = styled.div`
  position: relative;
  background-color: #ffffff !important;
`;

function BorrowerMarketing() {
  useEffect(() => {
    AOS.init({
      duration: 500,
      once: true,
    });
  }, []);
  return (
    <BorrowerContainer>
      <SEO title="TrueFi - Credit infrastructure for crypto" />
      <Header />
      <HeroSection />
      <ExploreTruefiSection />
      <InstitutionalUsersSection />
      <AuditedBySection />
      <LearnSection />
      <BorrowCtaSection />
      <Footer/>
    </BorrowerContainer>
  );
}

export default BorrowerMarketing;
