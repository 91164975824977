import React from 'react';
import styled from 'styled-components';
import { SliderNavBtn } from './SliderNavButton';
import { ArrowRight } from '../icons/ArrowRight';

export const SliderNav = ({ className, nextBtn, prevBtn }) => (
  <SliderNavWrapper className={className}>
    <SliderNavBtn ref={prevBtn} className="prevSlide">
      <ArrowRight />
    </SliderNavBtn>
    <SliderNavBtn ref={nextBtn} className="nextSlide">
      <ArrowRight />
    </SliderNavBtn>
  </SliderNavWrapper>
);

const SliderNavWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 24px;

  @media (max-width: 767px) {
    position: absolute;
    left: 50%;
    bottom: 80px;
    transform: translateX(-50%);
    z-index: 2;
  }
`;
