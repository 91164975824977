import styled from 'styled-components';
import { Text } from 'components/Text/Text';
import { SectionTitleLight } from 'components/Text/SectionTitle';
import { BaseButtonLinkStyles, buttonPrimaryStyles } from 'components/Button/Button';

export const Row = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 113px;
  margin-bottom: 213px;

  @media (max-width: 900px) {
    grid-column-gap: 45px;
    margin-bottom: 92px;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    margin-bottom: 40px;
  }
`;

export const SectionContainer = styled.div`
  @media (max-width: 767px) {
    padding: 0 16px;
  }
`;

export const SectionWrapper = styled.section`
  overflow: hidden;
  position: relative;
  background-color: #030303;
  padding: 179px 0 80px;

  @media (max-width: 900px) {
    padding: 120px 0 45px;
  }
  
  @media (max-width: 767px) {
    border-radius: 8px;
    padding: 40px 0 0 0;
  }
`;

export const SectionBackground = styled.img`
  position: absolute;
  top: 0;
  right: 0;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const SectionBackgroundMobile = styled.img`
  display: none;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const SectionContent = styled.section`
  position: relative;
  z-index: 1;

  @media (max-width: 767px) {
    padding-bottom: 80vw;
  }
`;

export const SectionTitle = styled.h2`
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: #FFFFFF;
  margin-bottom: 0;

  @media (max-width: 900px) {
    font-size: 32px;
  }
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 28px;
    text-align: left;
  }
`;

export const SectionName = styled(Text)`
  color: #D0D6E6;
  text-transform: uppercase;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const StyledText = styled(Text)`
  line-height: 32px;
  color: #D0D6E6;

  @media (max-width: 900px) {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    text-align: center;
  }
  
  span {
    color: #fff;
    font-weight: 600;
  }
`;

export const TextWrapper = styled.div`
  display: grid;
  grid-row-gap: 24px;
  width: 100%;
  max-width: 493px;

  @media (max-width: 900px) {
    max-width: 390px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const StyledSectionTitleLight = styled(SectionTitleLight)`
  @media (max-width: 767px) {
    font-size: 40px;
    text-align: center;
  }
  
  span {
    color: ${({ color }) => color};
  }
`;

export const Button = styled(BaseButtonLinkStyles)`
  ${buttonPrimaryStyles};
  width: 340px;
  height: 57px;
  
  svg {
    margin-left: 16px;
  }
`;
