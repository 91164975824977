import React from 'react';

import { Modal } from '../../Modal';
import { useResponsiveHelpers } from '../../../hooks/useResponsiveHelpers';

function BorrowerInquiryModal(props) {
  const { isShown, onRequestClose } = props;
  const { windowWidth } = useResponsiveHelpers();
  const useMobileLayout = windowWidth && windowWidth < 601;

  return (
    <Modal
      isShown={isShown}
      header=""
      onRequestClose={onRequestClose}
      onModalClose={() => {}}
      customStyles={{
        backdrop: {},
        modal: { width: useMobileLayout ? '100%' : '600px' },
        header: { borderBottom: 'none', padding: '16px 24px 0px' },
        content: {},
      }}
    >
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSdBK19KVi2fZk9G_0XEzFzu_nKYBrfZafh4j6ioueeFL2PVhw/viewform?embedded=true"
        width="100%"
        height="500"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
      >
        Loading…
      </iframe>
    </Modal>
  );
}

export { BorrowerInquiryModal };
