import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/layout/Container';
import { SectionTitleDark } from 'components/Text/SectionTitle';
import { Text } from 'components/Text/Text';
import { TabSection } from '../TabSection';

export const ExploreTruefiSection = () => (
  <SectionWrapper>
    <Container>
      <TextWrapper>
        <StyledSectionTitleDark data-aos="fade-up"><span>Lend</span>, <span>Borrow</span>, and <span>Manage</span> Assets On-Chain with TrueFi</StyledSectionTitleDark>
        <StyledText size="medium" data-aos="fade-up" data-aos-delay="250">
          TrueFi&apos;s capital market allows portfolio managers to <b>launch nearly any type of financial opportunity on-chain</b>, with access to global liquidity from day one. Portfolio managers also enjoy access to TrueFi's vast pool of lenders and borrowers, as well as TrustToken's institutional-grade <span><b>services designed to help bridge traditional finance to DeFi</b>.</span>
        </StyledText>
      </TextWrapper>
    </Container>
    <TabSection />
  </SectionWrapper>
);

const SectionWrapper = styled.section`
  margin-bottom: 154px;
  
`;

const StyledSectionTitleDark = styled(SectionTitleDark)`
  margin-bottom: 40px;

  @media (max-width: 900px) {
    margin-bottom: 32px;
  }
  
  span {
    color: #1A5AFF;
  }
`;

const StyledText = styled(Text)`
  font-size: 18px;
  line-height: 32px;

  @media (max-width: 900px) {
    font-size: 16px;
  }
  
  span {
    display: block;
  }
`;

const TextWrapper = styled.div`
  max-width: 808px;
  margin: 0 auto 104px auto;
  text-align: center;

  @media (max-width: 900px) {
    max-width: 584px;
    margin-bottom: 58px;
  }
`;
