import styled from 'styled-components';

export const Hero = styled.div`
  overflow: hidden;
  position: relative;
  padding: 148px 0 240px;

  @media (max-width: 1260px){
    background-size: 65% auto;
  }
  
  @media (max-width: 900px){
    background-size: contain;
    padding: 46px 0 58px;
  }
  
  @media (max-width: 767px){
    padding-top: 40px;
    background: none;
    padding-bottom: 88px;
  }
`;

export const HeroTitle = styled.h1`
  font-family: Regola Pro;
  font-weight: 600;
  font-size: 90px;
  line-height: 102px;
  margin-bottom: 0;
  color: #fff;

  @media (max-width: 768px){
    font-size: 38px;
    line-height: 46px;
    margin-bottom: 20px;
  }
`;
export const HeroContent = styled.p`
  font-family: Regola Pro;
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
  color: #fff;
  max-width: 648px;

  @media (max-width: 768px){
    font-size: 18px;
    line-height: 26px;
  }
`;
export const InterestRatesDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 90px;
  flex-wrap: wrap;
`;

export const InterestTerms = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 12px 24px;
  min-width: 576px;

  @media (max-width: 767px){
    min-width: 84%;
    padding: 12px 16px;
    justify-content: flex-start;
  }

  @media (min-width: 768px) {
    min-width: 46%;
  }
`;

export const ForBorrowersButton = styled.a`
  background: #0B0E14;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  padding: 7px 45px;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #e7eaf3;
  margin-right: 20px;
  transition:.5s;

  &:hover{
    background: #fff;
    color: #000;
  }

  @media (max-width: 767px){
    width: 72%;
    margin: 20px 0;
    text-align: center;
  }
`;
