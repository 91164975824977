import nibbio from 'images/adrian/logos/nibbio.svg';
import deltai from 'images/adrian/logos/deltai.svg';
import wintermute from 'images/adrian/logos/wintermute.svg';

export const institutionalUsers = [
  {
    logo: nibbio,
    quote: 'An excellent option for us',
    text: 'TrueFi has been an excellent option for us as we look to grow our borrowing activities. We’ve appreciated that as we’ve built up a reputation in the TrueFi system we’ve been able to progressively scale up our loan sizes',
  },
  {
    logo: wintermute,
    quote: 'A source of capital to scale up',
    text: 'It has been great being able to tap TrueFi as a source of capital to scale up our trading activities.  As we’ve continued to build reputation and a credit history within the system, we have enjoyed seeing loan terms and credit limits improve over time as well.',
  },
  {
    logo: deltai,
    quote: 'Tapping on-chain capital has helped delt.ai grow 60%',
    text: 'since our first loan request on TrueFi. Access to this funds helps us empower the leading LatAm fintechs to strengthen the local economy, all with very competitive capital rates and superior speed that you don’t find elsewhere.',
  },
];
